<template>
  <consultation-images
    v-if="Object.keys(images).length > 0"
    class="col-md-12 px-0"
    :case-id="examination.id"
    :images="images"
    :product="examination.type.name"
    :images-with-ai-filters="imagesWithAiFilters"
    :images-with-ai-filters-loading="imagesWithAiFiltersLoading"
    :data-image-metadata="imageMetadata"
    @update:images="images = $event"
    @update:images-with-ai-filters="imagesWithAiFilters = $event"
    @get-ai-filters="getAiFilters"
  />
</template>

<script>
import ConsultationImages from "../../AddOns/ConsultationImages.vue";
import { FIELD_CONSTANTS } from "../fieldMap";
import _find from "lodash/fp/find";
import _flow from "lodash/fp/flow";
import _get from "lodash/fp/get";

export default {
  components: {
    ConsultationImages,
  },
  /**
   * @see https://vuejs.org/guide/components/attrs.html#nested-component-inheritance
   * Since this component receives all props from the parent component, we need to disable
   * inheritance here since this will override certain props that we pass here to
   * the consultation-images component. (e.g. product)
   */
  inheritAttrs: false,
  props: {
    fields: Array,
    examination: Object,
  },
  data() {
    return {
      images: {},
      imagesWithAiFiltersLoading: false,
      imagesWithAiFilters: null,
    };
  },
  computed: {
    imageMetadata() {
      return _flow(
        _find({ type: "external_result" }),
        _get("metadata.images"),
      )(this.examination?.study_results);
    },
  },
  mounted: function () {
    this.mapImages();
  },
  methods: {
    mapImages: function () {
      const images = {};
      this.fields.forEach((field) => {
        if (field.type === FIELD_CONSTANTS.FIELD_TYPE_IMAGES) {
          if (field.name in this.examination.images) {
            images[field.name] = this.examination.images[field.name];
          }
        }
      });
      this.images = images;
    },
    getAiFilters: function () {
      return;
    },
  },
};
</script>
