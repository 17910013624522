<template>
  <tr :class="[expandUserInfo ? 'expanded-row' : '']">
    <td>
      <span
        v-if="!user.is_verified"
        class="badge badge-secondary m-0"
        :title="trans('forms.notVerifiedInfo')"
        >{{ trans("forms.notVerified") }}</span
      >
      <span class="d-block">{{ user.full_name }}</span>
    </td>
    <td>{{ user.email }}</td>
    <td>{{ user.phone_no }}</td>
    <td>{{ trans("roles." + dropdownRole(user)) }}</td>
    <td>{{ user.title }}</td>
    <td>
      <button
        type="button"
        data-toggle="collapse"
        :class="[expandUserInfo ? 'btn-primary' : 'btn-secondary', 'btn']"
        aria-expanded="false"
        :data-target="['#options_' + user.id]"
        @click="toggleUserOptions"
      >
        <span
          >{{
            expandUserInfo ? trans("center.showLess") : trans("center.showMore")
          }}
          <i
            class="far fa-angle-down"
            :class="[expandUserInfo ? 'fa-angle-up' : 'fa-angle-down', 'far']"
        /></span>
      </button>
    </td>
  </tr>
</template>

<script>
import DropdownRole from "../../../mixins/DropdownRole.vue";

export default {
  mixins: [DropdownRole],
  props: {
    user: {
      id: Number,
      is_verified: Boolean,
      full_name: String,
      email: String,
      phone_no: String,
      title: String,
    },
  },
  data() {
    return {
      expandUserInfo: false,
    };
  },
  methods: {
    toggleUserOptions: function () {
      this.expandUserInfo = !this.expandUserInfo;
    },
  },
};
</script>

<style lang="scss" scoped>
.far.fa-angle-down,
.far.fa-angle-up {
  font-size: large;
  vertical-align: middle;
}

tr td {
  vertical-align: middle !important;
}

tr td:first-of-type {
  border-left: 1px solid #ddd;
  padding-left: 1.5rem;
}

tr td:last-of-type {
  border-right: 1px solid #ddd;
}

.expanded-row {
  background-color: #f5f5f5;
  td {
    border-bottom: none !important;
    box-shadow: none;
    tr {
      border-bottom: none !important;
      box-shadow: none;
    }
  }

  &:hover {
    background-color: #f5f5f5;
  }
}

.table td {
  padding: 0.5rem;
}

.btn {
  min-width: 120px;
}
</style>
