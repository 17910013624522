<template>
  <div>
    <div class="mb-2 text-right">
      <button
        v-if="!expandUserInfo"
        type="button"
        class="btn btn-primary"
        @click="toggleUserOptions"
      >
        <i class="far fa-plus" />
        <span>{{ trans("center.addNewUser") }}</span>
      </button>
      <div v-else>
        <button
          type="button"
          class="btn btn-grey mx-2"
          @click="cancelAddingUser"
        >
          <span>{{ trans("general.modalCancelButton") }}</span>
        </button>
        <button
          type="button"
          :disabled="!isValid || loading"
          :title="isValid ? '' : trans('examination.requiredFieldMissing')"
          class="btn btn-primary"
          @click="addNewUser"
        >
          <i class="far fa-plus" />
          <span>{{ trans("center.addUser") }}</span>
        </button>
      </div>
    </div>

    <clip-loader v-if="loading" />

    <transition name="slide-fade" mode="out-in">
      <div v-if="expandUserInfo">
        <div class="panel-group">
          <div class="panel white">
            <h4 class="panel-title m-3">
              {{ trans("center.userSettings") }}
            </h4>

            <div class="panel-body">
              <user-settings
                :user="user"
                :errors="errors"
                :user-roles="userRoles"
                :view-as-region-admin="viewAsRegionAdmin"
              />

              <div class="buttons-group mb-2 col-md-12">
                <span class="float-left"
                  ><i class="far fa-asterisk" />
                  {{ trans("center.requiredField") }}</span
                >
              </div>
            </div>

            <h4 class="panel-title m-3">
              {{ trans("center.productsAndNotifications") }}
            </h4>

            <div class="panel-body">
              <user-products :products="user.products" />
              <div v-if="user.products.length === 0" class="text-center">
                <h6>{{ trans("center.noProducts") }}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-2 text-right">
          <button
            type="button"
            class="btn btn-grey mx-2"
            @click="cancelAddingUser"
          >
            <span>{{ trans("general.modalCancelButton") }}</span>
          </button>
          <button
            type="button"
            :disabled="!isValid || loading"
            :title="isValid ? '' : trans('examination.requiredFieldMissing')"
            class="btn btn-primary"
            @click="addNewUser"
          >
            <i class="far fa-plus" />
            <span>{{ trans("center.addUser") }}</span>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import UserSettings from "./UserSettings.vue";
import UserProducts from "../../Admin/Users/User/UserProducts.vue";
import { omit } from "lodash/fp";

export default {
  components: {
    UserSettings,
    UserProducts,
  },
  props: {
    centerData: Object,
    dataUrl: String,
    viewAsRegionAdmin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      expandUserInfo: false,
      expandSettings: false,
      expandProducts: false,
      user: {
        email: "",
        firstname: "",
        lastname: "",
        phone_no: "",
        title: null,
        comment: null,
        hide_case_answers: false,
        hide_personal_data: false,
        hsa_id: null,
        culture: "",
        products: [],
        roles: [],
        can_consult: null,
      },
      errors: [],
      userRoles: {
        consultant: false,
        senior_consultant: false,
        wound_user: false,
        user: true,
        center_admin: false,
        region_admin: false,
        external_resource: false,
        region_external_resource: false,
        admin: false,
        case_admin: false,
        consultation_creator: true,
        consultation_photographer: false,
      },
    };
  },
  computed: {
    isValid: function () {
      if (
        !this.user.culture ||
        !this.user.email ||
        !this.user.firstname ||
        !this.user.lastname
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  created: function () {
    this.getNewUser();
  },
  methods: {
    toggleUserOptions: function () {
      this.expandUserInfo = true;
      this.$emit("addingUser", true);
    },
    cancelAddingUser: function () {
      this.loading = false;
      this.getNewUser();
      this.expandUserInfo = false;
      this.errors = [];
      this.userRoles = {
        consultant: false,
        senior_consultant: false,
        wound_user: false,
        user: true,
        center_admin: false,
        region_admin: false,
        external_resource: false,
        region_external_resource: false,
        admin: false,
        case_admin: false,
        consultation_creator: false,
        consultation_photographer: false,
      };
      this.$emit("addingUser", false);
    },
    addNewUser: function () {
      this.errors = [];
      this.loading = true;

      // don't create users that can't create cases, unless they are only supposed to be photographers
      if (this.userRoles.consultation_photographer === false) {
        this.userRoles.consultation_creator = true;
      }

      this.user.userRoles = this.userRoles;
      this.user.center = this.centerData.id;

      axios
        .post(
          this.dataUrl + "/users",
          omit(["initials", "fullName", "full_name"], this.user),
        )
        .then((response) => {
          this.$notify({
            text: this.trans("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });
          this.loading = false;
          this.expandUserInfo = false;
          this.$emit("newUserAdded");
          this.getNewUser();
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    getNewUser: function () {
      axios
        .get(this.dataUrl + "/users/new")
        .then((data) => {
          this.user = data.data.user;

          let products = this.centerData.products.map((product) => {
            return {
              name: product.name,
              type: product.type,
              active: false,
              consensus_notifications: false,
              notification_new_document_consult_answer: false,
              notifications_new_examinations: false,
              unanswered_cases: false,
              cases_without_photos: false,
            };
          });

          this.user.products = products;

          Object.assign(
            this.$data.userRoles,
            this.$options.data.call(this).userRoles,
          );
        })
        .catch((error) => {
          console.log("Get new user error: ", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-title {
  padding: 1rem;
  background-color: #ddd;
  border-radius: 0.25rem;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
