<template>
  <ClipLoader v-if="isLoading" />
  <div v-else-if="isReady">
    <slot />
  </div>
  <ErrorAlert v-else :data-error="error" />
</template>
<script setup lang="ts" generic="T, E">
import ErrorAlert from "./ErrorAlert.vue";
import ClipLoader from "../components/Utils/ClipLoader.vue";
import { computed, toRefs } from "vue";
import { UseQueryReturnType } from "@tanstack/vue-query";

const props = defineProps<{ dataResource: UseQueryReturnType<T, E> }>();
const { dataResource } = toRefs(props);
const isLoading = computed(() => !!dataResource.value.isLoading.value);
const error = computed(() => dataResource.value.error.value);
const isReady = computed(() => !isLoading.value && !error.value);
</script>
