<template>
  <div class="panel panel-default panel-fill panel-actions">
    <div class="panel-heading panel-heading-underline">
      <h3 class="panel-title">
        {{ trans("examination.generalHeadline") }}
        <div class="dropdown left">
          <button
            id="dropdownMenuButton"
            class="btn btn-custom float-right"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ trans("general.action") }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#" @click.prevent="printCase()">{{
              trans("examination.tabLabelPrint")
            }}</a>
            <a
              v-if="$can('prioritise_cases')"
              class="dropdown-item"
              href="#"
              @click.prevent="updateCaseActions('isPrioritized')"
              >{{
                actions.isPrioritized
                  ? trans("general.actionPrioritizeButtonRemove")
                  : trans("general.actionPrioritizeButton")
              }}</a
            >
            <a
              v-if="$can('prioritise_cases')"
              class="dropdown-item"
              href="#"
              @click.prevent="updateCaseActions('isFlagged')"
              >{{
                actions.isFlagged
                  ? trans("general.actionFlagButtonRemove")
                  : trans("general.actionFlagButton")
              }}</a
            >
            <a
              v-if="$can('prioritise_cases')"
              class="dropdown-item"
              href="#"
              @click.prevent="updateCaseActions('isBookmarked')"
              >{{
                actions.isBookmarked
                  ? trans("general.actionBookmarkButtonRemove")
                  : trans("general.actionBookmarkButton")
              }}</a
            >
          </div>
        </div>
        <general-info-popup :product="product"></general-info-popup>
      </h3>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="m-b-20 col-md-6">
          <strong>{{ examination.patient.ssnLabel }}</strong>
          <br />
          <p v-if="examination.patient.hasAccess" class="text-muted">
            <router-link
              :to="{
                name: 'PatientOverview',
                params: { id: $route.params.id },
              }"
              :title="trans('examination.visitPatientOverview')"
            >
              {{ examination.patient.ssn }}
            </router-link>
            <i
              class="fal fa-copy p-2"
              :title="trans('general.copyToClipboard')"
              @click="copyPatientId(examination.patient.ssn)"
            ></i>
          </p>
          <p
            v-if="!examination.patient.hasAccess"
            class="text-muted"
            data-toggle="tooltip"
            data-placement="top"
            :title="trans('examination.noAccessToPatientViewMessage')"
          >
            {{ examination.patient.ssn }}
          </p>
        </div>
        <div class="m-b-20 col-md-6">
          <strong>{{ trans("patient.name") }}</strong>
          <br />
          <p
            v-dompurify-html:[DOMPURIFY_CONFIG_NAME.fullName]="
              examination.patient.fullName
            "
            class="text-muted"
          ></p>
        </div>
        <div v-if="product === 'moleUk'" class="m-b-20 col-md-6">
          <strong>{{ trans("patient.dateOfBirth") }}</strong>
          <br />
          <p class="text-muted">
            {{ examination.patient.dateOfBirth }}
          </p>
        </div>
        <div v-else class="m-b-20 col-md-6">
          <strong>{{ trans("patient.age") }}</strong>
          <br />
          <p class="text-muted">{{ examination.patient.age }}</p>
        </div>
        <div class="m-b-20 col-md-6">
          <strong>{{ trans("patient.gender") }}</strong>
          <br />
          <p class="text-muted">{{ examination.patient.gender }}</p>
        </div>
        <div class="m-b-20 col-md-6">
          <strong>{{ trans("patient.contactNumber") }}</strong>
          <br />
          <p v-if="examination.patient.telephone_number" class="text-muted">
            {{ examination.patient.telephone_number }}
          </p>
          <p v-else>-</p>
        </div>
        <div class="m-b-20 col-md-6">
          <strong>
            {{ trans("examination.patientCaseLabel") }}
          </strong>
          <br />
          <p class="text-muted">{{ examination.caseNo }}</p>
        </div>

        <div class="m-b-20 col-md-6 d-inline-flex align-items-end">
          <case-status
            :examination="examination"
            @close-case="closeCase"
          ></case-status>
        </div>
        <div
          v-if="
            $store.state.dermicusPlatform === 'stockholm' && product === 'mole'
          "
          class="m-b-20 col-md-6"
        >
          <strong>{{ trans("examination.referralInitialisedLabel") }}</strong>
          <br />
          <p class="text-muted">{{ examination.createdAt }}</p>
        </div>
        <div v-else class="m-b-20 col-md-6">
          <strong>{{ trans("examination.createdAtLabel") }}</strong>
          <br />
          <p class="text-muted">{{ examination.createdAt }}</p>
        </div>
        <div class="m-b-20 col-md-6">
          <strong>{{ trans("examination.createdByLabel") }}</strong>
          <br />
          <VDropdown :disabled="dermicusPlatform !== 'stockholm'">
            <template v-if="!fetchingUserContactInfo" #popper>
              <div>
                <b>{{ trans("users.personalInfoFullName") }}: </b
                >{{ userContactInfo[0].firstname }}
                {{ userContactInfo[0].lastname }}
              </div>
              <div>
                <b>{{ trans("users.emailLabel") }}: </b
                >{{ userContactInfo[0].email }}
              </div>
              <div>
                <b>{{ trans("users.contactNoLabel") }}:</b>
                {{ userContactInfo[0].phone_no }}
              </div>
            </template>
            <template v-if="fetchingUserContactInfo" #popper>
              <clip-loader></clip-loader>
            </template>

            <p class="text-muted">
              <span
                :class="dermicusPlatform === 'stockholm' ? 'popover-link' : ''"
                @click="
                  getUserContactInfo(
                    $route.params.id,
                    examination.createdUserId,
                  )
                "
                >{{ examination.createdUser }}</span
              >
              <span v-if="product === 'moleUk' && examination.createdUserTitle"
                >, {{ examination.createdUserTitle }}</span
              >
            </p>
          </VDropdown>
        </div>
        <div class="m-b-20 col-md-6">
          <strong>{{ trans("examination.centerLabel") }}</strong>
          <br />
          <p class="text-muted">{{ examination.center }}</p>
        </div>

        <div v-if="dermicusPlatform === 'stockholm'" class="m-b-20 col-md-6">
          <strong>{{ trans("examination.requisitionIdLabel") }}</strong>
          <br />
          <p class="text-muted">
            {{ examination.requisitionId }}
          </p>
        </div>

        <div v-if="examination.case.externalCaseNumber" class="m-b-20 col-md-6">
          <strong>{{ trans("examination.min_doktor_case_number") }}</strong>
          <br />
          <p class="text-muted">
            {{ examination.case.externalCaseNumber }}
          </p>
        </div>

        <div v-if="dermicusPlatform === 'stockholm'" class="m-b-20 col-md-6">
          <strong>{{ trans("examination.photographedAtLabel") }}</strong>
          <br />
          <p class="text-muted">
            {{ examination.photographedAt }}
          </p>
        </div>

        <div v-if="dermicusPlatform === 'stockholm'" class="m-b-20 col-md-6">
          <strong>{{ trans("examination.photographerLabel") }}</strong>
          <br />
          <VDropdown :disabled="dermicusPlatform !== 'stockholm'">
            <template v-if="!fetchingUserContactInfo" #popper>
              <div>
                <b>{{ trans("users.personalInfoFullName") }}: </b
                >{{ userContactInfo[0].firstname }}
                {{ userContactInfo[0].lastname }}
              </div>
              <div>
                <b>{{ trans("users.emailLabel") }}: </b
                >{{ userContactInfo[0].email }}
              </div>
              <div>
                <b>{{ trans("users.contactNoLabel") }}: </b
                >{{ userContactInfo[0].phone_no }}
              </div>
            </template>
            <template v-if="fetchingUserContactInfo" #popper>
              <clip-loader></clip-loader>
            </template>

            <p
              class="text-muted popover-link"
              @click="
                getUserContactInfo(
                  $route.params.id,
                  examination.photographerUserId,
                )
              "
            >
              {{ examination.photographerUser }}
            </p>
          </VDropdown>
        </div>

        <div v-if="dermicusPlatform === 'stockholm'" class="m-b-20 col-md-6">
          <strong>{{ trans("examination.photographerCommentLabel") }}</strong>
          <br />
          <p class="text-muted">{{ examination.case.photographerComment }}</p>
        </div>

        <div
          v-if="
            $store.state.user.has_ai_prioritization &&
            examination.isAiPrioritized
          "
          class="m-b-20 col-md-6"
        >
          <ai-priority-icon location="general-info"></ai-priority-icon>
        </div>

        <div
          v-if="$can('prioritise_cases') && actions.isPrioritized"
          class="m-b-20 col-md-6"
        >
          <strong>{{ trans("examination.priorityLabel") }}</strong>
          <br />
          <p class="text-muted">
            <i
              class="fas fa-exclamation-circle"
              :title="trans('general.casePrioritizedTitle')"
            />
          </p>
        </div>

        <div
          v-if="$can('prioritise_cases') && actions.isFlagged"
          class="m-b-20 col-md-6"
        >
          <strong>{{ trans("examination.flaggedCaseLabel") }}</strong>
          <br />
          <p class="text-muted">
            <i class="fas fa-flag" :title="trans('general.caseFlaggedTitle')" />
          </p>
        </div>
        <div
          v-if="$can('prioritise_cases') && actions.isBookmarked"
          class="m-b-20 col-md-6"
        >
          <strong>{{ trans("examination.bookmarkLabel") }}</strong>
          <br />
          <p class="text-muted">
            <i
              class="fas fa-star"
              :title="trans('general.caseBookmarkedTitle')"
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from "../../Utils/ClipLoader.vue";
import AiPriorityIcon from "../../Utils/AiFeatures/AiPriorityIcon.vue";
import GeneralInfoPopup from "./GeneralInfoPopup.vue";
import CaseStatus from "../Examination/ExaminationComponents/components/CaseStatus.vue";

import { copyPatientId } from "../../../helpers/platform";
import _noop from "lodash/fp/noop";
import { examinationCloseConfirm } from "../examination-close-confirm";
import { DOMPURIFY_CONFIG_NAME } from "../../../dompurify";

export default {
  components: {
    ClipLoader,
    AiPriorityIcon,
    GeneralInfoPopup,
    CaseStatus,
  },
  props: {
    examination: {
      caseNo: String,
      isErasable: Boolean,
      caseCloseAt: String,
      createdAt: String,
      createdUser: String,
      createdUserTitle: String,
      photographerUser: String,
      photographerComment: String,
      photographedAt: String,
      requisitionId: String,
      center: String,
      patient: {
        ssnLabel: String,
        ssn: String,
        id: Number,
        hasAccess: Boolean,
        fullName: String,
        dateOfBirth: String,
        age: String,
        gender: String,
      },
    },
    actions: {
      isPrioritized: Boolean,
      isBookmarked: Boolean,
      isFlagged: Boolean,
    },
    product: String,
  },
  data: function () {
    return {
      DOMPURIFY_CONFIG_NAME,
      dermicusPlatform: this.$store.state.dermicusPlatform,
      userContactInfo: [
        {
          firstname: "",
          lastname: "",
          email: "",
          phone_no: "",
        },
      ],
      fetchingUserContactInfo: false,
      copyPatientId: copyPatientId,
    };
  },
  methods: {
    closeCase: function () {
      examinationCloseConfirm(this.$vueAlert.confirm, this.examination)
        .then(() => this.$emit("closeCase"))
        .catch(_noop);
    },
    printCase: function () {
      this.$emit("printCase");
    },
    updateCaseActions: function (actionName) {
      this.$emit("updateCaseActions", actionName);
    },
    getUserContactInfo: function (caseId, userId) {
      this.fetchingUserContactInfo = true;

      axios
        .get(`/api/internal/examination-user/${caseId}/${userId}`)
        .then((response) => {
          const data = response.data;
          this.userContactInfo = data;
          this.fetchingUserContactInfo = false;
        })
        .catch((error) => {
          this.fetchingUserContactInfo = false;
          this.userErrorMessage(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@use "/resources/assets/sass/abstracts/variables";

.fal.fa-copy {
  cursor: pointer;

  &:hover {
    color: variables.$mellanBlue;
    transform: scale(1.08);
    font-weight: 400;
  }

  &:active {
    transform: scale(0.98);
  }
}
</style>
