<template>
  <field-panel
    :title="title"
    :actions-menu="
      viewOption === 'patient_examination'
        ? {
            onAction: onAction,
            examinationActions: examinationActions,
            product: product,
            loading: loading,
            errorUpdatingActions: errorUpdatingActions,
            caseClosedAt: caseClosedAt,
            viewOption: viewOption,
          }
        : undefined
    "
    :info-popup-text="
      !product.meta.name.toLowerCase().includes('tumour')
        ? getInfoPopupText()
        : ''
    "
  >
    <edit-examination
      v-if="editExamination"
      :visible="editExamination"
      :loading="loading"
      :examination="examination"
      :product-definition="product"
      edit-type="case_data"
      @on-cancel="onCancelEditExamination"
      @on-save="onSaveEditExamination"
    />

    <div class="panel-body">
      <div v-if="examinationData.length > 0" class="row">
        <column v-for="(field, index) in examinationData" :key="index">
          <field
            :examination-id="examination.id"
            :title="field.title"
            :data="field.data"
            :type="field.type"
            :extra-options="field.extraOptions"
          />
        </column>
      </div>
      <ExaminationAttachments
        v-if="showExaminationAttachments"
        :data-examination-id="examination.id"
      />
    </div>
    <VueJsDialogMixinWrapper
      v-if="!!showPrintDialogWithOptions"
      :data-component="showPrintDialogComponent"
      :data-options="showPrintDialogWithOptions"
      @close="closePrintCase"
    />
  </field-panel>
</template>

<script>
import Column from "./components/Column.vue";
import FieldPanel from "./components/FieldPanel.vue";
import Notifications from "../../.././Utils/notifications";
import EditExamination from "../../../Examinations/Examination/ExaminationComponents/components/EditExamination.vue";
import CustomPrintViewDialog from "../../Examination/ExaminationComponents/components/PrintCaseView.vue";

import { FIELD_CONSTANTS } from "../fieldMap";
import { getFieldType } from "../../../../helpers/product";
import {
  PRODUCT_ACTION_NAMES,
  STORE_CONSTANTS,
} from "../../../../helpers/definitions";
import { Api } from "../../../../helpers";
import { infoPopupText } from "../../../../helpers/misc";
import { examinationCloseConfirm } from "../../examination-close-confirm";
import VueJsDialogMixinWrapper from "../../../../mixins/VueJsDialogMixinWrapper.vue";
import { inject } from "vue";
import _isString from "lodash/fp/isString";
import _noop from "lodash/fp/noop";
import ExaminationAttachments from "./ExaminationAttachments.vue";
import { practitionerHttpService } from "../../../../app/practitioner-portal/store/practitioner-http-service";

export default {
  components: {
    VueJsDialogMixinWrapper,
    Column,
    FieldPanel,
    EditExamination,
    ExaminationAttachments,
  },
  props: {
    fields: Array,
    examination: {
      id: Number,
      case_data: Object,
    },
    product: Object,
    viewOption: String,
    options: Object,
  },
  setup() {
    return {
      showPatientLevelReports: inject("showPatientLevelReports", false),
    };
  },
  data() {
    return {
      examinationData: [],
      FIELD_CONSTANTS: FIELD_CONSTANTS,
      examinationActions: {
        isPrioritized: false,
        isFlagged: false,
        isBookmarked: false,
      },
      loading: false,
      editExamination: false,
      errorUpdatingActions: false,
      caseClosedAt: null,
      showPrintDialogComponent: CustomPrintViewDialog,
      showPrintDialogWithOptions: null,
    };
  },
  computed: {
    title() {
      /**
       * @todo Push this "title" into the product definition + check how we should localise it!
       *    Current done this way as this is done in several places right now, and all will
       *    need to be updated once we push this into the product definition.
       */
      const productName = this.product?.meta?.name;

      if (this.options?.title) {
        return this.options.title;
      } else if (_isString(productName) && /^moleuk$/i.test(productName)) {
        return "examination.consultationHeadlineUk";
      } else {
        return "examination.consultationHeadline";
      }
    },
    showExaminationAttachments() {
      return this.options?.show_examination_attachments ?? false;
    },
  },
  watch: {
    examination() {
      this.mapData();
    },
  },
  mounted: function () {
    this.mapData();
  },
  methods: {
    mapData: function () {
      this.setExaminationActions();

      const { fields, examination } = this;

      // flag case being closed
      this.caseClosedAt = this.examination
        ? this.examination.caseCloseAt
        : null;

      const examinationData = [];
      fields.forEach((field) => {
        const hasValue = field.name in examination.case_data;

        /**
         * TODO: This config override should be moved to the backend field definitions.
         * @see {@link https://gnosco.atlassian.net/browse/DER-2682}
         */
        const isWoundMeasurementField = [
          "ulcer_length",
          "ulcer_width",
          "ulcer_area",
        ].includes(field.name);

        if (field.visible) {
          const valueOptions = JSON.parse(
            JSON.stringify(JSON.parse(field.value_options)),
          );

          examinationData.push({
            title: field.name,
            data: hasValue ? examination.case_data[field.name] : "-",
            type:
              isWoundMeasurementField &&
              examination.images.wound_measurement !== undefined &&
              examination.woundMeasurementHasBeenUpdatedManually === false
                ? FIELD_CONSTANTS.FIELD_TYPE_WOUND_MEASUREMENT_TEXT_WITH_LABEL
                : getFieldType(field),
            disabled: field.disabled,
            extraOptions: {
              valueOptions: valueOptions,
              examination: examination.case_data,
            },
          });
        }
      });

      this.examinationData = examinationData;
    },
    setExaminationActions: function () {
      this.examinationActions.isPrioritized = this.examination.isPrioritized;
      this.examinationActions.isFlagged = this.examination.isFlagged;
      this.examinationActions.isBookmarked = this.examination.isBookmarked;
    },
    onAction: function (action) {
      if (action.submitable) this.updateCaseActions(action);
      else {
        switch (action.name) {
          case PRODUCT_ACTION_NAMES.EDIT_EXAMINATION:
            this.editExamination = true;
            break;
          case PRODUCT_ACTION_NAMES.PRINT_CASE:
            this.printCase();
            break;
          case PRODUCT_ACTION_NAMES.CLOSE_CASE:
            this.closeCase();
            break;
          case PRODUCT_ACTION_NAMES.USE_UCR:
            this.submitToUcr();
            break;
          case PRODUCT_ACTION_NAMES.UCR_PROMOTION:
            this.showUcrDialog();
            break;
        }
      }
    },
    submitToUcr: async function () {
      this.loading = true;
      try {
        const response = await practitionerHttpService.get(
          `/api/internal/tumour-wound/submit-ucr/${this.examination.id}`,
        );
        if (response.data.success)
          Notifications.saveSuccessCustomText(response.data.message);
        else Notifications.errorCustomText(response.data.message);
      } catch (error) {
        Notifications.error();
      }
      this.loading = false;
    },
    showUcrDialog: function () {
      this.$vueAlert
        .confirm(
          this.trans("examination.ucr_connection"),
          undefined,
          undefined,
          {
            confirmButtonText: this.trans("examination.ucr_button_visit"),
            cancelButtonText: this.trans("general.modalCancelButton"),
          },
        )
        .then(() => {
          window.open("https://www.rikssar.se/", "_blank");
        })
        .catch(_noop);
    },
    closeCase: async function () {
      try {
        await examinationCloseConfirm(this.$vueAlert.confirm, this.examination);
        await practitionerHttpService.get(
          `/api/internal/tumour-wound/close/${this.examination.id}`,
        );
        this.$store.dispatch(STORE_CONSTANTS.LOAD_PATIENT_CASES);
      } catch (e) {
        return;
      }
    },
    updateCaseActions: async function (action) {
      // update local action values
      this.examinationActions[action.key] = this.examinationActions[action.key]
        ? false
        : true;

      this.loading = true;
      this.errorUpdatingActions = false;

      practitionerHttpService
        .post(
          `/api/internal/tumour-wound/actions/${this.examination.id}`,
          this.examinationActions,
        )
        .then(() => {
          this.$store.dispatch(STORE_CONSTANTS.LOAD_PATIENT_CASES);
        })
        .then(() => {
          Notifications.saveSuccess();
          this.loading = false;
        })
        .catch((error) => {
          this.errorUpdatingActions = true;
          this.setExaminationActions();
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    printCase: function () {
      this.showPrintDialogWithOptions = {
        data: {
          uri: `/api/internal/examinations/${this.examination.id}/print`,
          caseNo: this.examination.caseNo,
          productDefinition: this.product,
          showPatientLevelReports: this.showPatientLevelReports,
        },
      };
    },
    closePrintCase() {
      this.showPrintDialogWithOptions = null;
    },
    onCancelEditExamination: function () {
      this.editExamination = false;
    },
    onSaveEditExamination: async function (values) {
      try {
        this.loading = true;
        await Api.post(
          `/api/internal/tumour-wound/${this.examination.id}`,
          values,
          this,
        );
        await this.$store.dispatch(STORE_CONSTANTS.LOAD_PATIENT_CASES);

        this.loading = false;
        this.editExamination = false;
        $(`#editExaminationModal-${this.examination.caseNo}`).modal("hide");
      } catch (error) {
        this.loading = false;
        this.editExamination = true;
        console.log("Save edited examination values error: ", error);
      }
    },
    getInfoPopupText: function () {
      return this.examinationData.length > 0
        ? infoPopupText(this.examinationData)
        : "";
    },
  },
};
</script>
