<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="panel panel-default panel-fill">
          <div class="panel-heading">
            <h3 class="panel-title">
              {{ trans("users.rolesHeadline") }}

              <info-popup :text-content="trans('tooltip.userRoles')" />
            </h3>
          </div>
          <div class="panel-body">
            <div class="row col-md-12">
              <div class="m-b-20 col-md-6">
                <label for="role">{{ trans("users.userRoleLabel") }}</label>
                <select2
                  id="role"
                  name="role"
                  :model-value="role"
                  @update:model-value="selectDropdownRole($event)"
                >
                  <option value />
                  <option value="user">
                    {{ trans("roles.user") }}
                  </option>
                  <option value="consultant">
                    {{ trans("roles.consultant") }}
                  </option>
                  <option value="senior_consultant">
                    {{ trans("roles.senior_consultant") }}
                  </option>
                  <option value="wound_user">
                    {{ trans("roles.wound_user") }}
                  </option>
                  <option
                    v-if="$store.state.dermicusPlatform === 'stockholm'"
                    value="consultation_photographer"
                  >
                    {{ trans("roles.consultation_photographer") }}
                  </option>
                </select2>
              </div>
              <div v-if="$route.params.id !== 'new'" class="m-b-20 col-md-6">
                <DerToggleWithLabel
                  :model-value="!userRoles.user"
                  :label="trans('users.blockedUser')"
                  @update:model-value="userRoles.user = !$event"
                />
              </div>
              <div class="m-b-20 col-md-6 tw-space-y-2">
                <DerToggleWithLabel
                  :label="trans('roles.external_resource')"
                  :model-value="hasAnyExternalResourceRole"
                  @update:model-value="updateHasAnyExternalResourceRole"
                />

                <select2
                  v-if="hasAnyExternalResourceRole"
                  name="externalResourceRole"
                  :model-value="selectedExternalResourceRole"
                  disable-clear="true"
                  @update:model-value="updateSelectedExternalResourceRole"
                >
                  <option value="" />
                  <option value="external_resource">
                    {{ trans("roles.external_resource_full_title") }}
                  </option>
                  <option value="region_external_resource">
                    {{ trans("roles.region_external_resource_full_title") }}
                  </option>
                </select2>

                <table class="table table-hover table-actions-bar">
                  <tbody>
                    <tr
                      v-for="(externalResource, index) in externalResourcesList"
                      :key="index"
                    >
                      <td v-if="externalResource">
                        {{ externalResource.case_no }}
                      </td>
                    </tr>
                    <button
                      v-if="externalResources.length > 5"
                      class="btn btn-grey btn-lg btn-block mt-2"
                      @click="showAllExternalCases = !showAllExternalCases"
                    >
                      <i
                        :class="[
                          showAllExternalCases
                            ? 'fa-angle-up'
                            : 'fa-angle-down',
                          'fas',
                        ]"
                      />
                      {{
                        showAllExternalCases
                          ? trans("center.showLess")
                          : trans("examination.consultationShowAllButton")
                      }}
                    </button>
                  </tbody>
                </table>
              </div>
              <div class="m-b-20 col-md-6">
                <DerToggleWithLabel
                  v-model="userRoles.study_participator"
                  :label="trans('roles.study_participator')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="panel panel-default panel-fill">
          <div class="panel-heading">
            <h3 class="panel-title">
              {{ trans("users.tabMenuLabelSettings") }}
              <info-popup :text-content="trans('tooltip.userSettings')" />
            </h3>
          </div>
          <div class="panel-body">
            <div class="row col-md-12">
              <div class="m-b-20 col-md-3">
                <div class="tw-flex tw-items-center tw-gap-x-2">
                  <DerToggleWithLabel
                    v-model="user.has_ai_prioritization"
                    :label="trans('admin.aiPrioritization')"
                    :disabled="!user.center_has_ai_prioritization"
                  />
                  <i
                    v-if="!user.center_has_ai_prioritization"
                    v-tooltip="
                      trans('aiFeatures.aiPrioritizationSettingDisabledInfo')
                    "
                    class="fas fa-question-circle"
                  />
                </div>
              </div>
              <div class="m-b-20 col-md-3">
                <div class="tw-flex tw-items-center tw-gap-x-2">
                  <DerToggleWithLabel
                    v-model="user.has_ai_diagnosis"
                    :label="trans('admin.aiDiagnosis')"
                    :disabled="!user.center_has_ai_diagnosis"
                  />
                  <i
                    v-if="!user.center_has_ai_diagnosis"
                    v-tooltip="
                      trans('aiFeatures.aiDiagnosisSettingDisabledInfo')
                    "
                    class="fas fa-question-circle"
                  />
                </div>
              </div>
              <div class="m-b-20 col-md-3">
                <DerToggleWithLabel
                  v-model="user.hide_case_answers"
                  :label="trans('users.hideConsultAnswerLabel')"
                />
              </div>
              <div class="m-b-20 col-md-3">
                <DerToggleWithLabel
                  v-model="user.hide_personal_data"
                  :label="trans('users.hidePersonalNoLabel')"
                />
              </div>
              <div class="m-b-20 col-md-3">
                <DerToggleWithLabel
                  v-model="userRoles.ai_tester"
                  :label="trans('users.toolsForImageAnalysis')"
                />
              </div>
              <div class="m-b-20 col-md-3">
                <DerToggleWithLabel
                  v-model="user.bypass_2fa"
                  :label="trans('users.bypass2faLabel')"
                />
                <i
                  v-tooltip="trans('users.bypass2faDescription')"
                  class="fas fa-question-circle"
                />
              </div>
            </div>

            <div class="row">
              <div class="m-b-20 col-md-12">
                <div
                  class="form-group"
                  :class="{ 'has-error': errors.culture }"
                >
                  <label for="culture">{{
                    trans("users.languageLabel")
                  }}</label>
                  <i class="far fa-asterisk" />
                  <select2 v-model="user.culture" name="culture">
                    <option
                      v-if="$store.state.dermicusPlatform === 'uk'"
                      value="en"
                    >
                      {{ trans("users.languageOptionEnglish") }}
                    </option>
                    <option value="sv">
                      {{ trans("users.languageOptionSwedish") }}
                    </option>
                    <option
                      v-if="$store.state.dermicusPlatform !== 'uk'"
                      value="en"
                    >
                      {{ trans("users.languageOptionEnglish") }}
                    </option>
                    <option value="nl">
                      {{ trans("users.languageOptionDutch") }}
                    </option>
                    <option value="de">
                      {{ trans("users.languageOptionGerman") }}
                    </option>
                    <option value="fr">
                      {{ trans("users.languageOptionFrench") }}
                    </option>
                    <option value="it">
                      {{ trans("users.languageOptionItalian") }}
                    </option>
                  </select2>

                  <form-error v-if="errors.culture" :errors="errors.culture" />
                </div>
              </div>
            </div>
            <span class="text-left"
              ><i class="far fa-asterisk" />
              {{ trans("center.requiredField") }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="panel panel-default panel-fill">
          <div class="panel-heading">
            <h3 class="panel-title">
              {{ trans("roles.adminAccess") }}
              <info-popup :text-content="trans('tooltip.userAdminRoles')" />
            </h3>
          </div>
          <div class="panel-body">
            <div class="row col-md-12">
              <div class="m-b-20 col-md-3">
                <DerToggleWithLabel
                  v-model="userRoles.admin"
                  :disabled="!userRoles.admin"
                  :label="trans('roles.dermicusAdmin')"
                />
              </div>

              <div class="m-b-20 col-md-3">
                <DerToggleWithLabel
                  v-model="userRoles.region_admin"
                  :label="trans('roles.region_admin')"
                />
              </div>

              <div class="m-b-20 col-md-3">
                <DerToggleWithLabel
                  v-model="userRoles.center_admin"
                  :label="trans('roles.center_admin')"
                />
              </div>

              <div class="m-b-20 col-md-3">
                <DerToggleWithLabel
                  v-model="userRoles.case_admin"
                  :label="trans('roles.case_admin')"
                />
              </div>

              <div class="m-b-20 col-md-3">
                <DerToggleWithLabel
                  v-model="userRoles.study_admin"
                  :label="trans('roles.study_admin')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <AdminUserStudyAdmin :data-user="user" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="panel panel-default panel-fill">
          <div class="panel-heading">
            <h3 class="panel-title">
              {{ trans("examination.ucr_user_connection") }}
            </h3>
          </div>

          <div class="panel-body">
            <div class="form-group" :class="{ 'has-error': errors.ucrError }">
              <label for="ucr-id">{{
                trans("examination.ucr_username")
              }}</label>
              <input
                id="ucr-id"
                v-model="ucr.id"
                type="text"
                name="ucr-id"
                class="form-control"
              />
              <form-error v-if="errors.ucrError" :errors="errors.ucrError" />
            </div>

            <div class="text-right">
              <button class="btn btn-primary" @click="syncUcr">
                {{ trans("examination.ucr_sync_user_action") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoPopup from "../../../Utils/InfoPopup.vue";
import AdminUserStudyAdmin from "../../../../app/admin/AdminUserStudyAdmin.vue";
import DerToggleWithLabel from "../../../../design-system/DerToggleWithLabel.vue";

export default {
  components: {
    AdminUserStudyAdmin,
    InfoPopup,
    DerToggleWithLabel,
  },
  props: {
    user: {
      blocked: Boolean,
      has_wound_area: Boolean,
      has_ai_prioritization: Boolean,
      has_ai_diagnosis: Boolean,
      bypass_2fa: Boolean,
      center_has_ai_prioritization: Boolean,
      center_has_ai_diagnosis: Boolean,
      use_square_images: Boolean,
      user_type: Number,
      role: Number,
      hide_case_answers: Boolean,
      hide_personal_data: Boolean,
      culture: String,
    },
    externalResources: null,
    ucr: {
      active: Boolean,
      id: String,
    },
    errors: {},
    userRoles: {
      consultant: Boolean,
      senior_consultant: Boolean,
      wound_user: Boolean,
      user: Boolean,
      center_admin: Boolean,
      region_admin: Boolean,
      external_resource: Boolean,
      region_external_resource: Boolean,
      admin: Boolean,
      case_admin: Boolean,
      ai_tester: Boolean,
      consultation_photographer: Boolean,
      consultation_creator: Boolean,
      study_admin: Boolean,
      study_participator: Boolean,
    },
    dropdownRole: String,
  },
  data: function () {
    return {
      role: this.dropdownRole,
      showAllExternalCases: false,
      lastSelectedExternalResourceRole: "external_resource",
    };
  },
  computed: {
    externalResourcesList: function () {
      if (
        !this.showAllExternalCases &&
        this.externalResources &&
        this.externalResources.length > 5
      ) {
        return this.externalResources.slice(0, 5);
      } else {
        return this.externalResources;
      }
    },
    hasAnyExternalResourceRole() {
      return !!(
        this.userRoles["external_resource"] ||
        this.userRoles["region_external_resource"]
      );
    },
    selectedExternalResourceRole() {
      if (this.userRoles["external_resource"]) {
        this.lastSelectedExternalResourceRole = "external_resource";
      } else if (this.userRoles["region_external_resource"]) {
        this.lastSelectedExternalResourceRole = "region_external_resource";
      }

      return this.lastSelectedExternalResourceRole;
    },
  },

  watch: {
    dropdownRole: function (newValue) {
      this.role = newValue;
    },
    "userRoles.admin": function (newValue) {
      let instance = this;

      if (newValue === false) {
        this.$vueAlert
          .confirm(
            this.trans("general.modalRemoveSuperadminMessage"),
            undefined,
            undefined,
            {
              confirmButtonText: this.trans("general.modalConfirmButton"),
              cancelButtonText: this.trans("general.modalCancelButton"),
            },
          )
          .then(function () {
            instance.userRoles.admin = false;
          })
          .catch(function () {
            instance.userRoles.admin = true;
          });
      }
    },
  },
  methods: {
    syncUcr: function () {
      this.$emit("syncUcr");
    },
    selectDropdownRole: function (selectedRole) {
      this.userRoles.consultant = selectedRole === "consultant";
      this.userRoles.consultation_creator = [
        "consultant",
        "senior_consultant",
        "user",
      ].includes(selectedRole);
      this.userRoles.consultation_photographer =
        selectedRole === "consultation_photographer";
      this.userRoles.senior_consultant = selectedRole === "senior_consultant";
      this.userRoles.wound_user = selectedRole === "wound_user";
      // WARNING: the 'user' role is controlled by the 'inactivated' toggle
    },
    updateHasAnyExternalResourceRole(value) {
      this.userRoles[this.selectedExternalResourceRole] = !!value;
    },
    updateSelectedExternalResourceRole(role) {
      this.lastSelectedExternalResourceRole = role;
      this.userRoles["external_resource"] = false;
      this.userRoles["region_external_resource"] = false;
      this.userRoles[role] = true;
    },
  },
};
</script>
