<template>
  <div>
    <header-admin :active-section="6" />

    <div class="mt-3">
      <ul id="myTab" class="nav nav-tabs" role="tablist">
        <li v-for="(tab, index) in tabsList" :key="index" class="nav-item">
          <router-link active-class="active" class="nav-link" :to="`${tab}`">
            {{ trans("admin.logsMenuItem" + tab) }}
          </router-link>
        </li>
        <li
          v-if="$store.state.dermicusPlatform === 'stockholm'"
          class="nav-item"
        >
          <router-link active-class="active" class="nav-link" to="takeCare">
            {{ trans("admin.logsMenuItemtakeCare") }}
          </router-link>
        </li>
        <li v-if="$store.state.dermicusPlatform === 'sweden'" class="nav-item">
          <router-link
            active-class="active"
            class="nav-link"
            to="minDoktorRetransfers"
          >
            {{ trans("admin.logsMenuItemminDoktorRetransfers") }}
          </router-link>
        </li>
      </ul>
    </div>

    <router-view class="tab-content" />
  </div>
</template>

<script>
import HeaderAdmin from "./../Header.vue";

export default {
  components: {
    HeaderAdmin,
  },
  data() {
    return {
      // all tabs that should be visible despite special conditions
      tabsList: ["system", "apiIn", "apiOut", "email"],
    };
  },
};
</script>
