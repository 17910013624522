<template>
  <datepicker
    :model-value="dateValue"
    :name="name ?? 'datepicker'"
    :input-format="shortDateFormatDateFns()"
    :placeholder="placeholder"
    :upper-limit="new Date()"
    :clearable="true"
    class="form-control"
    @update:model-value="handleUpdate"
  >
    <template #clear="{ onClear }">
      <button class="v3dp__clear" @click="onClear">
        <i class="fa fa-times" />
      </button>
    </template>
  </datepicker>
</template>

<script>
import Datepicker from "vue3-datepicker";
import {
  shortDateFormatDateFns,
  yyyymmddFormatter,
} from "../../helpers/platform";

export default {
  components: {
    Datepicker,
  },
  props: {
    name: String,
    placeholder: String,
    date: String,
  },
  data() {
    return {
      dateValue: this.date ? new Date(this.date) : null,
    };
  },
  watch: {
    date(newValue) {
      this.dateValue = newValue ? new Date(newValue) : null;
    },
  },
  methods: {
    shortDateFormatDateFns,
    handleUpdate(value) {
      this.dateValue = value;
      const dateInSaveFormat = value ? yyyymmddFormatter(value) : "";
      this.$emit("onDateChange", dateInSaveFormat);
    },
  },
};
</script>
