<template>
  <div class="mb-5">
    <header-admin :active-section="9" />

    <div class="text-right my-2">
      <button class="btn btn-grey" @click="back">
        {{ trans("general.back") }}
      </button>
      <button
        :disabled="!isValid || loading"
        :title="isValid ? '' : trans('examination.requiredFieldMissing')"
        class="btn btn-primary ml-1"
        @click="saveChanges"
      >
        {{ trans("general.formSaveLabel") }}
      </button>
    </div>

    <clip-loader v-show="loading" />

    <div class="m-t-10">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h3 class="panel-title">
            {{ trans("region.region") }}
          </h3>
        </div>
        <div class="panel-body">
          <div class="m-b-20 col-md-12" :class="{ 'has-error': errors.name }">
            <label for="regionName">{{ trans("region.regionName") }}</label>
            <i class="far fa-asterisk" />
            <br />
            <input
              id="regionName"
              v-model="region.name"
              type="text"
              name="regionName"
              class="form-control"
              :placeholder="trans('region.chooseDermicusRegion')"
            />
            <form-error v-if="errors.name" :errors="errors.name" />
          </div>
          <span class="pull-left">
            <i class="far fa-asterisk" />
            {{ trans("center.requiredField") }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="!loading" class="panel panel-default panel-fill">
      <div class="panel-heading">
        <h3 class="panel-title">
          {{ trans("patient.patientLookupType") }}
        </h3>
      </div>
      <div class="panel-body">
        <select v-model="region.patient_lookup_type" class="form-control">
          <option :key="null" :value="null">
            {{ trans(`region.patientLookupTypeNull`) }}
          </option>
          <option
            v-for="patientLookupType in patientLookupTypes"
            :key="patientLookupType.id"
            :value="patientLookupType.id"
          >
            {{ patientLookupType.display }}
          </option>
        </select>
      </div>
    </div>

    <region-products
      v-if="!loading"
      organisation="region"
      :products="products"
    />

    <region-settings
      v-if="!loading"
      organisation="region"
      :settings="features"
    />

    <!-- Region admin -->
    <div
      v-if="!loading && $route.params.id !== 'new'"
      class="panel panel-default panel-fill"
    >
      <div class="panel-heading">
        <h3 class="panel-title">
          {{ trans("region.regionAdmin") }}
        </h3>
      </div>
      <div class="panel-body table-responsive">
        <table class="table table-hover interactive-table">
          <thead>
            <tr>
              <th>{{ trans("forms.firstname") }}</th>
              <th>{{ trans("forms.lastname") }}</th>
              <th>{{ trans("forms.email") }}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-for="(admin, index) in region.region_admins" :key="index">
              <td @click="openRegionAdmin(admin.id)">
                {{ admin.firstname }}
              </td>
              <td @click="openRegionAdmin(admin.id)">
                {{ admin.lastname }}
              </td>
              <td @click="openRegionAdmin(admin.id)">
                {{ admin.email }}
              </td>
              <td>
                <button
                  class="btn btn-danger float-right"
                  @click="removeRegionAdmin(admin.id)"
                >
                  <span>{{ trans("general.formDeleteLabel") }}</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-if="regionCenters && regionCenters.length !== 0">
          <div
            class="m-b-20 col-md-12"
            :class="{ 'has-error': errors.message }"
          >
            <label for="primaryCenter">
              {{ trans("region.addNewAdmin") }}
            </label>
            <select2
              v-model="selectedUserId"
              :data-placeholder="trans('forms.chooseUserPlaceholder')"
              name="users"
            >
              <option value="" />
              <option
                v-for="(user, index) in activeRegionUsers"
                :key="index"
                :value="user.id"
              >
                {{ user.firstname }} {{ user.lastname }}
              </option>
            </select2>
            <form-error v-if="errors.message" :errors="errors.message" />
          </div>
        </div>
      </div>
    </div>

    <!-- Region connected centers -->
    <div
      v-if="!loading && $route.params.id !== 'new'"
      class="panel panel-default panel-fill"
    >
      <div class="panel-heading">
        <h3 class="panel-title">
          {{ trans("users.connectedCentersLabel") }}
        </h3>
      </div>
      <div class="panel-body table-responsive">
        <table class="table table-hover interactive-table">
          <thead>
            <tr>
              <th>{{ trans("center.name") }}</th>
              <th>{{ trans("center.primaryUsers") }}</th>
              <th>{{ trans("center.contactPerson") }}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-for="(center, index) in regionCenters" :key="index">
              <td @click="openCenter(center.id)">{{ center.name }}</td>
              <td @click="openCenter(center.id)">
                {{ center.non_blocked_primary_users_count }}
              </td>
              <td @click="openCenter(center.id)">
                {{ center.contact_person }}
              </td>
              <td>
                <button
                  class="btn btn-danger float-right"
                  @click="removeCenterFromRegion(center.id)"
                >
                  <span>{{ trans("general.formDeleteLabel") }}</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div>
          <div class="m-b-20 col-md-12">
            <label for="centers">
              {{ trans("region.addCenter") }}
            </label>
            <select2
              v-model="selectedCenterId"
              :data-placeholder="trans('forms.chooseCenterPlaceholder')"
              name="centers"
            >
              <optgroup>
                <option disabled />
                <option
                  v-for="(center, index) in centers"
                  :key="index"
                  :value="center.id"
                >
                  {{ center.name }}
                </option>
              </optgroup>
            </select2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useAsyncState } from "@vueuse/core";

const { state: patientLookupTypes } = useAsyncState(
  axios
    .get("/api/internal/admin/patient-lookup-types")
    .then((response) => response.data),
  [],
);
</script>
<script>
import HeaderAdmin from "../Header.vue";

import RegionProducts from "../Centers/AdminCenterProducts.vue";
import RegionSettings from "../Centers/AdminCenterSettings.vue";

import RoleId from "../../Utils/Roles";
import {
  getProductNameByType,
  mapAvailableProductSettings,
} from "../../../helpers/product";
import { regionCentersGet } from "../../../store/core/regions";
import _map from "lodash/fp/map";

export default {
  components: {
    HeaderAdmin,
    RegionProducts,
    RegionSettings,
  },
  data() {
    return {
      loading: false,
      region: {
        name: "",
        region_admins: [],
        patient_lookup_type: null,
      },
      regionCenters: [],
      products: {},
      features: {
        ucr: {
          active: false,
          config: {
            center_id: null,
          },
        },
        regionskaneDicom: {
          active: false,
          config: {},
        },
        educations: {
          active: false,
          config: {},
        },
        webdoc: {
          active: false,
          config: {},
        },
      },
      errors: [],
      centers: [],
      selectedCenterId: null,
      regionUsers: [],
      selectedUserId: null,
      regionRole: null,
    };
  },
  computed: {
    isValid: function () {
      if (!this.region.name) {
        return false;
      } else {
        return true;
      }
    },
    activeRegionUsers: function () {
      let activeUsers = this.regionUsers.filter((user) => !user.blocked);

      let usersWithoutAdminRole = [];

      activeUsers.forEach((user) => {
        let userWithoutAdminRole = user.roles.find(
          (role) => role.name === "region_admin",
        );
        if (userWithoutAdminRole === undefined) {
          usersWithoutAdminRole.push(user);
        }
      });

      return usersWithoutAdminRole;
    },
  },
  mounted: function () {
    this.getRegion();
  },
  methods: {
    getRegion: async function () {
      this.loading = true;

      try {
        /**
         * When creating a new organisation, the id is "new".
         * So then we don't need to do the `regionCentersGet`, because it would fail,
         * and in this case we don't need that data anyway.
         */
        const centerId = parseInt(this.$route.params.id, 10);

        const [data, centers] = await Promise.all([
          axios.get("/api/internal/admin/regions/" + this.$route.params.id),
          centerId ? regionCentersGet(centerId) : null,
        ]);
        let collection = data.data;
        this.region = collection.region;
        this.regionCenters = centers;
        this.regionUsers = collection.region.users;

        const productsResponse = await axios.get("/api/internal/products");
        this.products = mapAvailableProductSettings(productsResponse.data);

        this.setFeaturesData(collection.region.features);
        this.setProductData(collection.region.features);

        this.getCenters();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.userErrorMessage(error);
      }
    },
    setProductData: function (features) {
      const ids = Object.keys(this.products).map(
        (product) => this.products[product].id,
      );
      features.forEach((feature) => {
        if (ids.includes(feature.type)) {
          this.products[getProductNameByType(feature.type)].active = true;
        }
      });
    },
    setFeaturesData: function (features) {
      for (let key in features) {
        let featureType = features[key].type;
        if (featureType == 4) {
          this.features.ucr.active = true;
          if (features[key].config) {
            this.features.ucr.config = features[key].config;
          }
        } else if (featureType == 7) {
          this.features.educations.active = true;
        } else if (featureType == 9) {
          this.features.international.active = true;
        } else if (featureType == 10) {
          this.features.webdoc.active = true;
          if (features[key].config) {
            this.features.webdoc.config = features[key].config;
          }
        } else if (featureType == 13) {
          this.features.regionskaneDicom.active = true;
        }
      }
    },
    getCenters: function () {
      axios
        .get("/api/internal/admin/all-centers")
        .then((data) => {
          let collection = data.data;

          // Filter out centers that are already added to organisation
          if (this.regionCenters) {
            let regionCenterIds = _map("id", this.regionCenters);
            this.centers = collection.filter(
              (center) => !regionCenterIds.includes(center.id),
            );
          } else {
            this.centers = data.data;
          }
        })
        .catch((error) => {
          console.log("Get centers error: ", error);
        });
    },
    addCenterToRegion: function () {
      axios
        .post(
          "/api/internal/admin/regions/attach-region/" +
            this.selectedCenterId +
            "/" +
            this.region.id,
        )
        .then((response) => {
          this.selectedCenterId = null;
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.userErrorMessage(error);
        });
    },
    removeCenterFromRegion: function (centerId) {
      this.$vueAlert
        .confirm(
          this.trans("region.modalConfirmationDeleteCenterFromRegion"),
          undefined,
          undefined,
          {
            confirmButtonText: this.trans("general.modalConfirmButton"),
            cancelButtonText: this.trans("general.modalCancelButton"),
          },
        )
        .then(() => {
          axios
            .post("/api/internal/admin/regions/detach-region/" + centerId)
            .then((response) => {
              this.$notify({
                text: this.trans("general.notificationSaved"),
                type: "success",
                duration: 1000,
              });
              this.getRegion();
            })
            .catch((error) => {
              this.userErrorMessage(error);
            });
        })
        .catch(() => {
          return;
        });
    },
    addNewAdmin: async function () {
      this.errors = [];
      this.regionRole = await RoleId("region_admin");

      axios
        .post(
          "/api/internal/admin/roles/assign/" +
            this.regionRole.id +
            "/" +
            this.selectedUserId,
        )
        .then((response) => {
          this.selectedUserId = null;
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.userErrorMessage(error);
        });
    },
    removeRegionAdmin: function (adminId) {
      this.$vueAlert
        .confirm(
          this.trans("region.modalConfirmationDeleteAdminFromRegion"),
          undefined,
          undefined,
          {
            confirmButtonText: this.trans("general.modalConfirmButton"),
            cancelButtonText: this.trans("general.modalCancelButton"),
          },
        )
        .then(async () => {
          this.regionRole = await RoleId("region_admin");

          axios
            .post(
              "/api/internal/admin/roles/remove/" +
                this.regionRole.id +
                "/" +
                adminId,
            )
            .then((response) => {
              this.$notify({
                text: this.trans("general.notificationSaved"),
                type: "success",
                duration: 1000,
              });
              this.getRegion();
            })
            .catch((error) => {
              this.userErrorMessage(error);
            });
        })
        .catch(() => {
          return;
        });
    },
    saveChanges: function () {
      this.loading = true;

      let saveUrl =
        "/api/internal/admin/regions" +
        (this.$route.params.id !== "new" ? "/" + this.$route.params.id : "");

      this.region.features = { ...this.features, ...this.products };
      let postData = this.region;

      this.errors = [];

      if (this.selectedCenterId) {
        this.addCenterToRegion();
      }

      if (this.selectedUserId) {
        this.addNewAdmin();
      }

      axios
        .post(saveUrl, postData)
        .then((response) => {
          this.$notify({
            text: this.trans("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });
          this.loading = false;

          if (response.data.isNew) {
            this.$router.push({ name: "AdminRegions" });
          }
          this.getRegion();
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    back: function () {
      this.$router.push({ name: "AdminRegions" });
    },
    openRegionAdmin: function (adminId) {
      this.$router.push({ name: "AdminUsersEdit", params: { id: adminId } });
    },
    openCenter: function (centerId) {
      this.$router.push({ name: "AdminCentersEdit", params: { id: centerId } });
    },
  },
};
</script>
