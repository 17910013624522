<template>
  <div class="chat-row">
    <div v-if="comment">
      <div v-if="userCanEdit" class="toolbar float-right">
        <a href="#" @click.prevent="onEdit()">{{
          trans("examination.diagnosReplyEditButton")
        }}</a>
        <br />
      </div>

      <div class="textholder">
        <label>{{ label }}</label>
        <div>
          <VDropdown v-if="comment.tooltip" class="tw-inline">
            <template #popper>
              <div v-for="(value, key) in comment.tooltip">
                <b>{{ key }}:</b>
                {{ value }}
              </div>
            </template>

            <span class="popover-link">
              {{ comment.userName }}
            </span>
          </VDropdown>
          <span v-else class="text-muted font-weight-bold">
            {{ comment.userName }}
          </span>
          <span class="font-weight-bold">({{ comment.created }})</span>
          <a
            v-if="comment.editedCommentId > 0"
            class="toolbar font-weight-bold"
            data-toggle="modal"
            :data-target="`#commentHistory-${comment.id}`"
            @click="getCommentHistory"
          >
            {{ trans("examination.modelCommentHasBeenEdited") }}
            {{ comment.updated }}</a
          >
        </div>
        <div class="comment-text pt-1">
          {{ comment.comment }}
        </div>
      </div>
    </div>

    <div
      :id="`commentHistory-${comment.id}`"
      class="modal fade comment-history-modal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">
              {{ trans("examination.modelCommentHasBeenEditedlabel") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="m-b-20 col-md-12">
                <div
                  v-for="(previousComment, index) in previousComments"
                  :key="index"
                  class="comment-line"
                >
                  <b
                    >{{ previousComment.userName }} ({{
                      previousComment.created
                    }})</b
                  >
                  <div class="text">
                    {{ previousComment.comment }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    userContactInfo: {
      firstname: String,
      lastname: String,
      email: String,
      phone_no: String,
    },
    comment: {
      userName: String,
      created: String,
      comment: String,
    },
    previousComments: Array,
    userCanEdit: Boolean,
    displayUserContact: Boolean,
  },
  methods: {
    onEdit: function () {
      this.$emit("on-edit", this.comment);
    },
    getCommentHistory: function () {
      this.$emit("on-get-history", this.comment);
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-text {
  word-break: break-word;
  word-wrap: break-word;
  white-space: break-spaces;
  user-select: text;
  cursor: text;
}
</style>
