<template>
  <component
    v-bind="component.props"
    :is="component.element"
    :key="component.key"
    @is-valid="component.events.isValid"
  />
</template>
<script>
import { translator } from "../../../../../helpers/misc";
import { fieldMap } from "../../fieldMap";
import _isBoolean from "lodash/fp/isBoolean";
import { lang } from "../../../../../i18n";

export default {
  inheritAttrs: false,
  props: {
    title: String,
    data: [String, Object, Number, Boolean, Array],
    disabled: Boolean,
    required: Boolean,
    type: String,
    examinationId: Number,
    formValue: [String, Object, Number, Array],
    extraOptions: Object,
    valueOptions: Array,
    viewOption: String,
  },
  emits: ["isValid"],
  computed: {
    component() {
      const {
        type,
        title,
        data,
        disabled,
        required,
        examinationId,
        formValue,
        extraOptions,
        viewOption,
        valueOptions,
      } = this;

      const translate = (value) => translator(`examination.${value}`);

      const translateData = (value) => {
        // Booleans should not be converted to empty strings (see YesNo.vue)
        if (_isBoolean(value)) {
          return value;
        } else if (!value) {
          return "";
        } else if (typeof value === "string" && value.trim() !== "") {
          return translate(value) || value;
        } else if (typeof value === "string" && value.trim() === "") {
          return "-";
        } else if (typeof value === "object" && Array.isArray(value)) {
          return value.map((val) => ({
            ...val,
            title: translate(val.title) || val.title,
          }));
        } else if (typeof value === "object") {
          const translated = {};
          Object.keys(value).forEach(
            (key) => (translated[translate(key) || key] = value[key]),
          );
          return translated;
        }

        return value;
      };

      const translatedData = translateData(data);

      return {
        element: fieldMap[type],
        key: title,
        props: {
          /**
           * Currently translation of titles is done here for product defs v1.
           *
           * @see {import("../../../../../../../../app/Dermicus/Products/Field.ts").Field}
           * @todo as FE does API calls with the language header, or we could at
           *    least, translations could easily be handled by the BE as part of
           *    prepping the data before sending. This is something to look into
           *    when moving to product defs v2.
           */
          title: lang.get(`examination.${title}`) || lang.get(title) || title,
          data: translatedData,
          disabled: disabled,
          required: required,
          examinationId: examinationId,
          formValue: formValue,
          extraOptions: extraOptions,
          valueOptions,
          viewOption: viewOption,
        },
        events: {
          isValid: (newValue) => {
            this.$emit("isValid", newValue);
          },
        },
      };
    },
  },
};
</script>
