<template>
  <div>
    <PatientInfoHeader :data-patient="dataPatient" />
    <hr />
    <div id="image-compare">
      <image-compare
        ref="imageCompare"
        :load-on-mount="true"
        :external-collection="gallery.data"
      >
        <div
          v-if="gallery.paginate.current_page < gallery.paginate.last_page"
          class="col-md-3 load-more"
        >
          <a class="load btn btn-primary col-12 mb-3" @click="getMoreImages()">
            {{ trans("users.showMoreNotificationButton") }}
          </a>
          <clip-loader v-show="imagesLoadingMore" />
        </div>
      </image-compare>
    </div>
  </div>
</template>

<script>
import ImageCompare from "../Examinations/AddOns/ImageCompare.vue";
import { getProductNameByType } from "../../helpers/product";
import PatientInfoHeader from "./PatientInfoHeader.vue";

export default {
  components: {
    ImageCompare,
    PatientInfoHeader,
  },
  props: {
    dataPatient: {
      type: Object,
    },
  },
  data() {
    return {
      imagesLoadingMore: false,
      gallery: {
        data: [],
        paginate: {
          current_page: null,
          last_page: null,
        },
      },
      galleryFilterOption: 2,
      collectionWithEncodedImg: {},
      getProductNameByType: getProductNameByType,
      collection: [],
    };
  },
  mounted() {
    this.getImages();
  },
  methods: {
    getImages: function () {
      if (this.collection.length === 0) {
        axios
          .get("/api/internal/patients/" + this.$route.params.id + "/images")
          .then((response) => {
            this.collection = response.data;
            this.concatResults(this.collection.data);
            this.gallery.paginate = this.collection.paginate;
          })
          .catch((error) => {
            this.userErrorMessage(error);
          });
      } else {
        return;
      }
    },
    concatResults: function (examinations) {
      const additions = examinations.map((examination) => {
        return {
          ...examination,
          id: examination.examinationId,
        };
      });
      this.gallery.data = this.gallery.data.concat(additions);
    },
    getMoreImages: function () {
      this.imagesLoadingMore = true;

      axios
        .get(this.gallery.paginate.next_page_url)
        .then((response) => {
          let collection = response.data;
          if (collection.data) {
            this.concatResults(collection.data);
            this.gallery.paginate = collection.paginate;
            this.imagesLoadingMore = false;
          } else {
            this.imagesLoadingMore = false;
          }
        })
        .catch((error) => {
          this.userErrorMessage(error);
          this.imagesLoadingMore = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#image-compare {
  margin-top: 1rem;
}
</style>
