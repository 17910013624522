<template>
  <div class="table-responsive">
    <button
      v-if="isSmallScreen"
      class="btn btn-grey btn-block"
      @click="toggleExpandTable"
    >
      <span v-if="!isExpanded"
        ><i class="fal fa-angle-double-left" />
        {{ trans("patient.expandTable") }}
        <i class="fal fa-angle-double-right"
      /></span>
      <span v-else
        ><i class="fal fa-angle-double-right" />
        {{ trans("patient.shrinkTable") }}
        <i class="fal fa-angle-double-left"
      /></span>
    </button>

    <table class="table table-hover">
      <thead class="sortable-table-header">
        <tr class="patient-list-header">
          <th v-for="(column, index) in columns" :key="index">
            {{ column }}
          </th>
        </tr>
      </thead>

      <tbody class="tab-pane">
        <template
          v-for="(collection, index) in examinationCollections"
          :key="'patient-header' + index"
        >
          <patient-examination-header
            :examination-collection="collection"
            :is-expanded="isExpanded"
          />

          <tr class="hiddenRow">
            <td :colspan="columns.length" class="hiddenRowContent">
              <patient-examination-container
                class="pt-3"
                :product-definition="productDefinition"
                :examination-collection="collection"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import PatientExaminationContainer from "./PatientExaminationContainer.vue";
import PatientExaminationHeader from "./PatientExaminationHeader.vue";

export default {
  components: {
    PatientExaminationContainer,
    PatientExaminationHeader,
  },
  props: {
    examinationCollections: Array,
    productDefinition: Object,
  },
  data: function () {
    return {
      columns: null,
      mobileScreenSize: 415,
      isSmallScreen: false,
      smallScreenTableHeaders: [
        "",
        this.trans("examination.patientCaseLabel_mobile_header"),
        this.trans("examination.lesion_location_mobile_header"),
        this.trans("examination.caseStatusLabel"),
        "",
      ],
      bigScreenTableHeaders: [
        "",
        this.trans("examination.patientCaseLabel"),
        this.trans("examination.lesion_location"),
        this.trans("examination.createdAtLabel"),
        this.trans("examination.lastConsultationLabel"),
        this.trans("examination.createdByLabel"),
        this.trans("examination.caseStatusLabel"),
        "",
      ],
      isExpanded: true,
    };
  },
  created: function () {
    this.screenSize();
    this.columnNumber();
  },

  methods: {
    toggleExpandTable: function () {
      this.isExpanded = !this.isExpanded;
      this.columnNumber();
    },
    columnNumber: function () {
      if (this.isSmallScreen && !this.isExpanded) {
        this.columns = this.smallScreenTableHeaders;
      } else {
        this.columns = this.bigScreenTableHeaders;
      }
    },
    screenSize: function () {
      const width = screen.width;

      if (width < this.mobileScreenSize) {
        this.isSmallScreen = true;
        this.isExpanded = false;
      } else {
        this.isSmallScreen = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-list-header {
  height: 100px;
  width: 100%;
  height: 52px;
  background-color: #f5f5f5;

  th {
    padding: 0.5rem;
  }
}

.patient-list-header > label {
  word-break: break-all;
  margin: 0 !important;
}

.hiddenRow {
  background: #ffff;
  color: #676a6c !important;

  .hiddenRowContent .collapse.show {
    border-top: 2px solid #dee2e6 !important;
  }

  .hiddenRowContent {
    padding: 0 !important;
    position: relative;
    top: -5px;
    border-top: none;
  }

  &:hover {
    background-color: #ffff;
  }
}

table {
  border-collapse: separate;
  border-spacing: 0 0.3rem;

  tr {
    td {
      border-bottom: 2px solid #f5f5f5 !important;

      &:first-of-type {
        border-left: 2px solid #f5f5f5;
      }

      &:last-of-type {
        border-right: 2px solid #f5f5f5;
      }
    }
  }
}
</style>
