<template>
  <div>
    <label :for="inputId"
      >{{ title }}
      <i v-if="required" class="far fa-asterisk" role="img" />
    </label>
    <VueSelect
      v-model="selectedValue"
      :input-id="inputId"
      :name="title"
      :disabled="disabled"
      :options="options"
      append-to-body
      :calculate-position="calculatePositionWithPopper"
      @update:model-value="onInput"
    />
    <div v-if="withOther && otherSelected">
      <label v-if="otherLabel" class="mt-3"
        >{{ otherLabel }}
        <i v-if="otherRequired" class="far fa-asterisk" role="img" />
      </label>
      <FormTextfield
        :disabled="disabled"
        :required="otherRequired"
        :extra-options="otherExtraOptions"
        @is-valid="otherIsValid = $event"
        @field-unmount="clearOtherValue"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { v4 as uuidV4 } from "uuid";
import VueSelect from "vue-select";
import { computed, ref, toRefs, watch, shallowRef } from "vue";
import FormTextfield from "./FormTextfield.vue";
import { FixedProps } from "../../../../../lib-vue/fixed-props";
import { lang } from "../../../../../i18n";
import { calculatePositionWithPopper } from "../../../../../helpers/popper";

const emit = defineEmits<{
  (e: "isValid", payload: boolean): void;
}>();

const props = defineProps<{
  title?: string;
  required?: boolean;
  disabled?: boolean;
  data: Array<{ value: string; label: string }>;
  formValue?: { value: string };
  extraOptions?: {
    key?: string;
    onValueChange: (payload: { key?: string; value?: string }) => void;
    fieldOptions?: {
      other_value?: string;
      other_label?: string;
      other_key?: string;
      other_required?: boolean;
    };
  };
}>();

const inputId = uuidV4();
const { data, formValue, extraOptions, required } = toRefs(
  props as FixedProps<typeof props>,
);
const options = computed(() =>
  data.value.filter(({ value }) => value !== "pickerSelectionDefault"),
);
const selectedValue = ref<{ value: string }>();
watch(formValue, () => (selectedValue.value = formValue.value), {
  immediate: true,
});
const otherIsValid = shallowRef(false);
const withOther = computed(
  () => !!extraOptions.value?.fieldOptions?.other_value,
);
const otherLabel = computed(
  () =>
    extraOptions.value?.fieldOptions?.other_label &&
    lang.get(extraOptions.value?.fieldOptions?.other_label),
);
const otherSelected = computed(
  () =>
    selectedValue.value?.value ===
    extraOptions.value?.fieldOptions?.other_value,
);
const otherExtraOptions = computed(() => ({
  key: extraOptions.value?.fieldOptions?.other_key,
  onValueChange: extraOptions.value?.onValueChange,
}));
const otherRequired = computed(
  () => !!extraOptions.value?.fieldOptions?.other_required,
);
const isValid = computed(() => {
  if (!required.value) {
    return true;
  }
  if (
    selectedValue.value?.value === undefined ||
    selectedValue.value?.value === null
  ) {
    return false;
  }
  if (
    withOther.value &&
    otherSelected.value &&
    otherRequired.value &&
    !otherIsValid.value
  ) {
    return false;
  }
  return true;
});
watch(isValid, () => emit("isValid", isValid.value), { immediate: true });

function onInput(event) {
  /**
   * vue-select emits null when clearing the value. In this case we need to set the value explicitly
   * to null, and not fall back to undefined.
   */
  extraOptions.value?.onValueChange({
    key: extraOptions.value.key,
    value: event === null ? null : event?.value,
  });
}

function clearOtherValue() {
  otherExtraOptions.value?.onValueChange({
    key: otherExtraOptions.value.key,
    value: null,
  });
}
</script>
