import {
  DOCUMENTATION_PERMISSION_TYPES,
  PRODUCTS,
  genericPatientProducts,
} from "./definitions";
import { translator } from "./misc";

export const getGenericPatientProductTypes = () => {
  return genericPatientProducts.map((product) => getProductType(product));
};

export const PRODUCT_ID = {
  MOLE: 1,
  MOLE_UK: 11,
  REGION_SKANE_MOLE: 110,
  PHOTO: 3,
  PHOTO_UK: 115,
  WOUND: 120,
  WOUND_LITE: 125,
  WOUND_LEGACY: 2,
  WOUND_UK: 105,
  TUMOUR_WOUND: 100,
  INTERNATIONAL: 9,
};

// TODO: Should be refactored/removed
export const getImageLabelByProductType = (type, imageLabel) => {
  const labels = {
    [PRODUCT_ID.MOLE]: {
      overview: "examination.imageOverview",
      close: "examination.imageClose",
      dermatoscopy_1: "examination.imageDermatoscopFirst",
      dermatoscopy_2: "examination.imageDermatoscopSecond",
    },
    [PRODUCT_ID.WOUND_LEGACY]: {
      overview: "examination.imageOverview",
      close: "examination.imageClose",
      bandage: "examination.imageBandage",
      extra: "examination.imageExtra",
    },
    [PRODUCT_ID.PHOTO]: {
      overview: "examination.imagePhotoFirst",
      close: "examination.imagePhotoSecond",
      dermatoscopy_1: "examination.imagePhotoThird",
      dermatoscopy_2: "examination.imagePhotoFourth",
    },
    [PRODUCT_ID.MOLE_UK]: {
      overview: "examination.imageOverview",
      close: "examination.imageClose",
      dermatoscopy_1: "examination.imageDermatoscopFirst",
      dermatoscopy_2: "examination.imageDermatoscopSecond",
    },
    [PRODUCT_ID.TUMOUR_WOUND]: {
      overview: "examination.imageOverview",
      close: "examination.imageClose",
      extra: "examination.imageExtra",
      bandage: "examination.imageBandage",
      signedConsentForm: "examination.signedConsentForm",
    },
    [PRODUCT_ID.WOUND_UK]: {
      overview: "examination.imageOverview",
      close: "examination.imageClose",
      bandage: "examination.imageBandage",
      extra: "examination.imageExtra",
      wound_measurement: "examination.imageWoundMeasurement",
    },
    [PRODUCT_ID.REGION_SKANE_MOLE]: {
      overview: "examination.imageOverview",
      close: "examination.imageClose",
      dermatoscopy_1: "examination.imageDermatoscopFirst",
      dermatoscopy_2: "examination.imageDermatoscopSecond",
    },
    [PRODUCT_ID.PHOTO_UK]: {
      overview: "examination.imagePhotoFirst",
      close: "examination.imagePhotoSecond",
      dermatoscopy_1: "examination.imagePhotoThird",
      dermatoscopy_2: "examination.imagePhotoFourth",
    },
    [PRODUCT_ID.WOUND]: {
      overview: "examination.imageOverview",
      close: "examination.imageClose",
      bandage: "examination.imageBandage",
      extra: "examination.imageExtra",
      wound_measurement: "examination.imageWoundMeasurement",
    },
    [PRODUCT_ID.WOUND_LITE]: {
      overview: "examination.imageOverview",
      close: "examination.imageClose",
      bandage: "examination.imageBandage",
      extra: "examination.imageExtra",
      wound_measurement: "examination.imageWoundMeasurement",
    },
  };
  return labels[type][imageLabel];
};

export const getProductType = (product) => {
  switch (product.toLowerCase()) {
    case PRODUCTS.MOLE.toLowerCase():
      return PRODUCT_ID.MOLE;
    case PRODUCTS.WOUND_LEGACY.toLowerCase():
      return PRODUCT_ID.WOUND_LEGACY;
    case PRODUCTS.PHOTO.toLowerCase():
      return PRODUCT_ID.PHOTO;
    case PRODUCTS.MOLE_UK.toLowerCase():
      return PRODUCT_ID.MOLE_UK;
    case PRODUCTS.MOLE_EDUCATIONS.toLowerCase():
      return PRODUCT_ID.MOLE;
    case PRODUCTS.TUMOUR_WOUND.toLowerCase():
      return PRODUCT_ID.TUMOUR_WOUND;
    case PRODUCTS.REGION_SKANE_MOLE.toLowerCase():
      return PRODUCT_ID.REGION_SKANE_MOLE;
    case PRODUCTS.INTERNATIONAL.toLowerCase():
      return PRODUCT_ID.INTERNATIONAL;
    case PRODUCTS.WOUND_UK.toLowerCase():
      return PRODUCT_ID.WOUND_UK;
    case PRODUCTS.PHOTO_UK.toLowerCase():
      return PRODUCT_ID.PHOTO_UK;
    case PRODUCTS.WOUND.toLowerCase():
      return PRODUCT_ID.WOUND;
    case PRODUCTS.WOUND_LITE.toLowerCase():
      return PRODUCT_ID.WOUND_LITE;
    default:
      return PRODUCT_ID.MOLE;
  }
};

export const getProductNameByType = (type) => {
  switch (type) {
    case PRODUCT_ID.MOLE:
      return PRODUCTS.MOLE;
    case PRODUCT_ID.WOUND_LEGACY:
      return PRODUCTS.WOUND_LEGACY;
    case PRODUCT_ID.PHOTO:
      return PRODUCTS.PHOTO;
    case PRODUCT_ID.MOLE_UK:
      return PRODUCTS.MOLE_UK;
    case PRODUCT_ID.TUMOUR_WOUND:
      return PRODUCTS.TUMOUR_WOUND;
    case PRODUCT_ID.REGION_SKANE_MOLE:
      return PRODUCTS.REGION_SKANE_MOLE;
    case PRODUCT_ID.INTERNATIONAL:
      return PRODUCTS.INTERNATIONAL;
    case PRODUCT_ID.WOUND_UK:
      return PRODUCTS.WOUND_UK;
    case PRODUCT_ID.PHOTO_UK:
      return PRODUCTS.PHOTO_UK;
    case PRODUCT_ID.WOUND:
      return PRODUCTS.WOUND;
    case PRODUCT_ID.WOUND_LITE:
      return PRODUCTS.WOUND_LITE;
    default:
      return PRODUCTS.MOLE;
  }
};

export const getProductNameTranslation = (name) => {
  const formattedName = name.toLowerCase().replace(/\s/g, "");
  let productName = "";
  switch (formattedName) {
    case "mole":
      productName = translator("general.mole");
      break;
    case "moleuk":
      productName = translator("general.moleuk");
      break;
    case "regionskanemole":
      productName = translator("general.regionSkaneMole");
      break;
    case "photo":
      productName = translator("general.photo");
      break;
    case "photouk":
      productName = translator("general.photouk");
      break;
    case "wound":
      productName = translator("general.wound");
      break;
    case "woundlegacy":
      productName = translator("general.woundLegacy");
      break;
    case "wounduk":
      productName = translator("general.woundUK");
      break;
    case "woundlite":
      productName = translator("general.woundLite");
      break;
    case "tumourwound":
      productName = translator("general.tumourWound");
      break;
  }

  return productName;
};

export const getProductRouteName = (productName) => {
  const formattedName = productName.toLowerCase().replace(/\s/g, "");
  let routeName = "";
  switch (formattedName) {
    case "mole":
      routeName = "mole";
      break;
    case "moleuk":
      routeName = "mole-uk";
      break;
    case "regionskanemole":
      routeName = "region-skane-mole";
      break;
    case "photo":
      routeName = "photo";
      break;
    case "photouk":
      routeName = "photo-uk";
      break;
    case "wound":
      routeName = "wound";
      break;
    case "woundlegacy":
      routeName = "wound-legacy";
      break;
    case "wounduk":
      routeName = "wound-uk";
      break;
    case "woundlite":
      routeName = "wound-lite";
      break;
    case "tumourwound":
      routeName = "tumour-wound";
      break;
  }

  return routeName;
};

export const filterImagesByProduct = (images, productType) => {
  const filtered = { ...images };
  delete filtered["location"];
  delete filtered["signedConsentForm"];
  return filtered;
};

export const getFieldType = (field) => {
  let type = "";
  if (field.frontend_type) type = field.frontend_type;
  else type = field.type;
  return type;
};

export const fetchProduct = async (productId, centerId, store) => {
  const { state, commit } = store;
  /**
   * @todo This caching was removed because the product itself can be different depending on centerId.
   * If we would want to cache this in the future, centerId should be taken into account aswell.
   */
  // if (productId in state.products) {
  //   commit("setCurrentPatientProductType", productId);
  //   return state.products[productId];
  // } else {
  try {
    const result = await axios.get(
      `/api/internal/products/${productId}/frontend/${centerId}`,
    );
    if (result.status === 200) {
      commit("saveProduct", {
        id: productId,
        product: result.data,
      });
      commit("setCurrentPatientProductType", productId);
      return result.data;
    }
  } catch (e) {
    console.log("Fetch product error: ", e);
  }
};

// TODO: Adjust Tumour Wound definition to enable read write permission for this field
export const getEnabledDocumentationTypes = (product) => {
  const enabledTypes = [];
  Object.keys(DOCUMENTATION_PERMISSION_TYPES).forEach((permission) => {
    if (productHasPermission(permission, product)) {
      enabledTypes.push({
        name: DOCUMENTATION_PERMISSION_TYPES[permission],
        read: true,
        edit: true,
      });
    }
  });
  return enabledTypes;
};

export const productHasPermission = (permission, product) => {
  return product.permissions.includes(permission);
};

export const getFormValue = (formValue, translator) => {
  if (!formValue) return;
  const hasMultipleValues =
    typeof formValue === "object" && formValue.constructor === Object;
  if (hasMultipleValues && formValue) {
    const result = [];
    Object.keys(formValue).forEach((val) => {
      if (formValue[val]) {
        result.push({
          label: translator(`examination.${val}`),
          value: val,
        });
      }
    });
    return result;
  }
  const translation = translator(`examination.${formValue}`);
  return {
    label: translation ? translation : `${formValue}`,
    value: formValue,
  };
};

export const getLastUpdatedExamination = (examinationCollections) => {
  const getNewestExamination = (a, b) => {
    if (
      b &&
      new Date(b.createdAt).getTime() > new Date(a.createdAt).getTime()
    ) {
      return b;
    } else {
      return a;
    }
  };
  let latestExamination = undefined;
  examinationCollections.forEach((examinationCollection) => {
    if (
      "examinations" in examinationCollection &&
      examinationCollection.examinations.length > 0
    ) {
      examinationCollection.examinations.forEach((examination) => {
        latestExamination = getNewestExamination(
          examination,
          latestExamination,
        );
      });
    } else {
      latestExamination = getNewestExamination(
        examinationCollection.main_examination,
        latestExamination,
      );
    }
  });
  return latestExamination;
};

export const mapAvailableProductSettings = (products) => {
  const result = {};
  products.forEach((product) => {
    // So stupid... Need to align product name usage over the plattform
    const name =
      product.name.replace(/\s/g, "").charAt(0).toLowerCase() +
      product.name.slice(1);

    result[name] = {
      active: false,
      config: {},
      id: product.id,
    };
  });
  return result;
};
