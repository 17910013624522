<template>
  <div v-if="products.length > 0" class="row">
    <div v-for="(product, index) in products" :key="index" class="col-md-6">
      <div class="panel panel-default hover-effect">
        <div class="panel-heading p-0">
          <div
            :class="[
              { grayscale: !product.active },
              productImageClass(product.name),
            ]"
            class="widget-img"
          >
            {{ productName(productNameByType(product.type)) }}
          </div>
        </div>
        <div class="panel-body">
          <div class="row col-md-12">
            <div class="col-md-12">
              <DerToggleWithLabel
                v-model="product.active"
                :label="trans('users.notificationActive')"
              />
            </div>
          </div>

          <horizontal-line
            v-if="product.active"
            class="mb-3"
            :text="trans('users.notificationHeadline')"
          />

          <div v-if="product.active" class="row col-md-12 tw-gap-y-8">
            <div class="col-4">
              <DerToggleWithLabel
                v-model="product.notifications_new_examinations"
                :label="trans('users.notificationNewCase')"
              />
            </div>
            <div class="col-4">
              <DerToggleWithLabel
                v-model="product.notification_new_document_consult_answer"
                :label="trans('users.notificationNewConsultAnswer')"
              />
            </div>
            <div class="col-4">
              <DerToggleWithLabel
                v-model="product.consensus_notifications"
                :label="trans('users.notificationNewChatConsultAnswer')"
              />
            </div>
            <div class="col-4">
              <DerToggleWithLabel
                v-model="product.new_consensus_channel_notifications"
                :label="trans('users.notificationNewConsensusGroupChannel')"
              />
            </div>
            <div
              v-if="product.name.toLowerCase().includes('mole')"
              class="col-4"
            >
              <DerToggleWithLabel
                v-model="product.unanswered_cases"
                :label="trans('users.notificationUnansweredCases')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalLine from "../../../Utils/HorizontalLine.vue";
import {
  getProductNameTranslation,
  getProductNameByType,
} from "../../../../helpers/product";
import DerToggleWithLabel from "../../../../design-system/DerToggleWithLabel.vue";

export default {
  components: {
    HorizontalLine,
    DerToggleWithLabel,
  },
  props: {
    products: {},
    user: {},
  },
  methods: {
    productImageClass: function (productName) {
      const name = productName.toLowerCase().replace(/\s+/g, "");
      if (name.includes("wound")) {
        return "wound";
      } else if (name.includes("mole")) {
        return "mole";
      } else {
        return "photo";
      }
    },
    productNameByType: function (type) {
      return getProductNameByType(type);
    },
    productName: function (name) {
      return getProductNameTranslation(name);
    },
  },
};
</script>
