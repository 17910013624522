<template>
  <div>
    <div
      :id="'options_' + userData.id"
      class="collapse multi-collapse col-lg-12"
    >
      <div class="panel-group">
        <div class="panel white">
          <div class="panel-heading">
            <h4 :class="[expandSettings ? 'expanded-opt' : '', 'panel-title']">
              <a
                data-toggle="collapse"
                :href="'#settings_' + userData.id"
                @click="toggleSettings"
                ><i
                  :class="[
                    expandSettings ? 'fa-angle-up' : 'fa-angle-down',
                    'fas',
                  ]"
                />
                {{ trans("center.userSettings") }}</a
              >
            </h4>
          </div>
          <div :id="'settings_' + userData.id" class="panel-collapse collapse">
            <div class="panel-body">
              <user-settings
                v-if="userRolesLoaded"
                :user="userData"
                :errors="errors"
                :user-roles="userRoles"
                :view-as-region-admin="viewAsRegionAdmin"
                @user-settings-changes="$emit('userSettingsChanges')"
              />

              <div class="buttons-group mb-2 col-md-12 text-right">
                <span class="float-left mr-1"
                  ><i class="far fa-asterisk" />
                  {{ trans("center.requiredField") }}</span
                >
                <button
                  type="button"
                  class="btn btn-secondary mb-1"
                  @click="sendPasswordReset"
                >
                  <span v-if="user.is_verified">{{
                    trans("center.sendPasswordReset")
                  }}</span>
                  <span v-if="!user.is_verified">{{
                    trans("center.resendVerificationEmail")
                  }}</span>
                </button>
                <button
                  type="button"
                  class="btn btn-primary mb-1"
                  :disabled="!isValid || loading"
                  :title="
                    isValid ? '' : trans('examination.requiredFieldMissing')
                  "
                  @click="saveChanges"
                >
                  <span>{{ trans("center.saveChanges") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="panel-group">
        <div class="panel white">
          <div class="panel-heading">
            <h4 :class="[expandProducts ? 'expanded-opt' : '', 'panel-title']">
              <a
                data-toggle="collapse"
                :href="'#products_' + userData.id"
                @click="toggleProducts"
                ><i
                  :class="[
                    expandProducts ? 'fa-angle-up' : 'fa-angle-down',
                    'fas',
                  ]"
                />
                {{ trans("center.productsAndNotifications") }}</a
              >
            </h4>
          </div>
          <div :id="'products_' + userData.id" class="panel-collapse collapse">
            <div class="panel-body">
              <user-products :products="userData.products" :user="userData" />
              <div v-if="userData.products.length === 0" class="text-center">
                <h6>{{ trans("center.noProducts") }}</h6>
              </div>
              <div class="buttons-group text-right">
                <button
                  type="button"
                  class="btn btn-primary"
                  :disabled="!isValid || loading"
                  :title="
                    isValid ? '' : trans('examination.requiredFieldMissing')
                  "
                  @click="saveChanges"
                >
                  <span>{{ trans("center.saveChanges") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserSettings from "./UserSettings.vue";
import UserProducts from "../../Admin/Users/User/UserProducts.vue";

import Notifications from "../../Utils/notifications";

export default {
  components: {
    UserSettings,
    UserProducts,
  },
  props: {
    userData: Object,
    dataUrl: String,
    centerData: Object,
    viewAsRegionAdmin: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["userSettingsChanges"],
  data() {
    return {
      loading: false,
      user: {},
      errors: [],
      expandSettings: false,
      expandProducts: false,
      roles: [],
      userRoles: {
        consultant: false,
        senior_consultant: false,
        user: false,
        center_admin: false,
        region_admin: false,
        external_resource: false,
        region_external_resource: false,
        admin: false,
        case_admin: false,
        consultation_photographer: false,
        consultation_creator: false,
      },
      userRolesLoaded: false,
    };
  },
  computed: {
    isValid: function () {
      if (
        !this.user.culture ||
        !this.user.email ||
        !this.user.firstname ||
        !this.user.lastname ||
        (this.user.id && !this.user.username)
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    userData: [
      {
        handler: "getUserData",
      },
    ],
    "userRoles.consultation_photographer": function (newValue) {
      let instance = this;

      if (newValue === true) {
        instance.userRoles.consultation_photographer = true;
        instance.userRoles.consultation_creator = false;
      } else if (newValue === false) {
        instance.userRoles.consultation_photographer = false;
        instance.userRoles.consultation_creator = true;
      }
    },
  },
  mounted: function () {
    this.loadRoles();
    this.user = this.userData;
  },
  methods: {
    loadRoles: function () {
      this.user = this.userData;

      // assign role to true if the role is in the assigned user roles list
      let assignedUserRoles = this.user.roles;
      assignedUserRoles.map((value) => {
        this.userRoles[value.name] = true;
      });
      this.userRolesLoaded = true;
    },
    saveChanges: function () {
      /**
       * We want to show a dialog to confirm that you are deactivating a user.
       * We can check this by seeing if the current user is not blocked, and the new
       * userRoles.user value is false.
       *
       * The direct mutation of props is very confusing and error-prone here, as well as
       * the duplication of the various roles/userRoles, so this seems like the easiest way to
       * handle this for now.
       */
      if (this.userRoles.user === false && this.user.blocked === false) {
        this.$vueAlert
          .confirm(
            this.trans("general.modalBlockUserMessage"),
            undefined,
            undefined,
            {
              confirmButtonText: this.trans("general.modalConfirmButton"),
              cancelButtonText: this.trans("general.modalCancelButton"),
            },
          )
          .then(() => {
            // If confirmed, proceed with saving.
            this.submitForm();
          })
          .catch(() => {
            // If canceled, abort the save.
            return false;
          });
      } else {
        this.submitForm();
      }
    },
    submitForm() {
      this.errors = [];
      this.loading = true;

      this.user.userRoles = this.userRoles;
      this.user.center = this.centerData.id;

      axios
        .post(this.dataUrl + "/users/" + this.user.id, this.user)
        .then(() => {
          this.$emit("userSettingsChanges");
          this.loading = false;
          Notifications.saveSuccess();
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    sendPasswordReset: function () {
      axios
        .post(this.dataUrl + "/users/password/reset/" + this.userData.id)
        .then(() => {
          Notifications.saveSuccessCustomText(
            "general.notificationPasswordResetSent",
          );
        })
        .catch((error) => {
          this.userErrorMessage(error);
        });
    },
    getUserData(userData) {
      let data = userData;
      this.user = data;
      this.loadRoles();
    },
    toggleSettings: function () {
      this.expandSettings = !this.expandSettings;
    },
    toggleProducts: function () {
      this.expandProducts = !this.expandProducts;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "/resources/assets/sass/abstracts/variables";

.far.fa-angle-down,
.far.fa-angle-up {
  font-size: large;
  vertical-align: middle;
}
.hiddenRow {
  padding: 0 !important;
  background: #f5f5f5;
  padding: 1rem;
}
.white {
  background: white;
}
.expanded-opt,
.expanded-opt.panel-title a {
  color: variables.$dermicusBlue;
}

.panel-title > a {
  display: block;
  color: #626773;
  &:hover {
    color: variables.$mellanBlue;
  }
}
.panel-group {
  margin: 1rem;
}
.panel-body {
  border: none;
}
.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group > .white > div.panel-heading {
  border: none !important;
}

.buttons-group {
  margin: 1rem;
  .btn {
    margin-right: 0.3rem;
  }
}
</style>
